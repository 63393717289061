import * as BasketApi from '@/api/basket';
import { queries } from '@/api/queries';
import { MutationConfig } from '@/types';
import { useMutation, useQueryClient } from '@tanstack/vue-query';

type CreateVoucherOptions = {
  config?: MutationConfig<typeof BasketApi.deleteBasket>;
};

const useDeleteBasket = ({ config }: CreateVoucherOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(queries.baskets.all);
      queryClient.refetchQueries({ queryKey: queries.basketTipPreview._def });
    },
    ...config,
    mutationFn: BasketApi.deleteBasket,
  });
};

export default useDeleteBasket;
