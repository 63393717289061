import { useQuery, skipToken } from '@tanstack/vue-query';
import * as TakeawayBasketApi from '@/api/takeawayBasket';
import { RANDOM_UK_PHONE_NUMBER } from '@/constants/utils';
import { queries } from '@/api/queries';
import { checkIfFullPostcode } from '@/utils/address';

type AddressRefParams = Ref<{
  address1: string | null;
  address2: string | null;
  city: string | null;
  zip: string | null;
  phone: string | null;
  lat: string | null;
  lon: string | null;
  country: string | null;
} | null>;

type DeliveryEstimationParams = {
  restaurantId: Ref<string | null>;
  timeSlot: Ref<'asap' | string | null>;
  address: AddressRefParams;
  numberOfItems?: Ref<number>;
};

const useGetDeliveryEstimation = (params: DeliveryEstimationParams) => {
  const isFullPostcode = computed(() =>
    Boolean(
      (params.address.value?.address1 && params.address.value.address1 !== 'ignore_me') ||
        (params.address.value?.zip && checkIfFullPostcode(params.address.value.zip))
    )
  );
  const enabled = computed(() =>
    Boolean(
      params.timeSlot.value &&
        params.restaurantId.value &&
        params.address.value?.zip &&
        params.address.value?.lat &&
        params.address.value?.lon &&
        params.address.value.country &&
        params.address.value.address1 &&
        params.address.value.city &&
        isFullPostcode.value
    )
  );

  const queryFn = computed(() =>
    enabled.value
      ? () =>
          TakeawayBasketApi.getDeliveryEstimation({
            address1: params.address.value!.address1 || '',
            address2: params.address.value!.address2 || '',
            city: params.address.value!.city || '',
            zip: params.address.value!.zip!,
            phone: params.address.value?.phone || RANDOM_UK_PHONE_NUMBER,
            lat: params.address.value!.lat!,
            lon: params.address.value!.lon!,
            country: params.address.value!.country!,
            restaurantId: params.restaurantId.value!,
            timeSlot: params.timeSlot.value!,
            numberOfItems: params.numberOfItems?.value || 0,
          })
      : skipToken
  );

  return useQuery({
    ...queries.takeaway.eta(params),
    queryFn,
  });
};

export default useGetDeliveryEstimation;
